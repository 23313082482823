import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Request } from "@/plugins/http";
import VueAxios from "vue-axios";
import "./style/element-variables.scss";
import "./style/index.scss";
import "./style/public.scss";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import print from "vue3-print-nb";
import { CountDown } from "vant";

const app = createApp(App);
app.use(VueAxios, Request.init());
app.use(ElementPlus, {
  locale: zhCn,
});
createApp(App).use(ElementPlus).use(store).use(router).use(print).use(CountDown).mount("#app");
