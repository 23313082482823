export function getToken(): string | boolean {
  return localStorage.getItem("CW_TOKEN") ?? false;
}

export function setToken(token: string): void {
  localStorage.setItem("CW_TOKEN", token);
}

export function removeToken(): void {
  localStorage.removeItem("CW_TOKEN");
  localStorage.removeItem("CW_INFO");
  localStorage.removeItem("CW_AUTH");
  localStorage.removeItem("CW_BACK_LIST");

  sessionStorage.clear();
}

export function setPassword(info: string): void {
  localStorage.setItem("CW_PASSWORD", info);
}

export function getPassword(): any | boolean {
  return localStorage.getItem("CW_PASSWORD") ?? false;
}

export function setUserInfo(info: string): void {
  localStorage.setItem("CW_INFO", info);
}

export function getUserInfo(): any | boolean {
  return localStorage.getItem("CW_INFO") ?? false;
}

export function setAuth(info: string): void {
  localStorage.setItem("CW_AUTH", info);
}

export function getAuth(): any | boolean {
  return localStorage.getItem("CW_AUTH") ?? false;
}

export function setArea(info: string): void {
  localStorage.setItem("CW_AREA", info);
}

export function getArea(): any | boolean {
  return localStorage.getItem("CW_AREA") ?? false;
}
