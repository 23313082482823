import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import { getToken } from "@/plugins/cookie";

export const Menu: Array<RouteRecordRaw> = [
  // {
  //   path: "/money",
  //   name: "money",
  //   meta: {
  //     icon: "",
  //     router: false,
  //   },
  //   redirect: "/money/money-list",
  //   children: [
  //     {
  //       path: "/money/money-list",
  //       name: "MoneyList",
  //       component: () => import("@/views/money-manager/MoneyList.vue"),
  //       meta: {
  //         title: "金额统计列表",
  //         icon: "",
  //         menuType: "Menu",
  //       },
  //     },
  //   ],
  // }
];

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () => import("@/views/Login.vue"),
  //   meta: {
  //     title: "登陆",
  //     icon: "",
  //     menuType: "Home",
  //   },
  // },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "首页",
      icon: "",
      router: true,
    },
    redirect: "/money/money-list",
    children: [
      {
        path: "/money/money-list",
        name: "MoneyList",
        component: () => import("@/views/money-manager/MoneyList.vue"),
        meta: {
          title: "首页数据",
          icon: "",
          menuType: "Home",
        },
      },
	  {
	    path: "/user/user-list",
	    name: "UserList",
	    component: () => import("@/views/user-manager/UserList.vue"),
	    meta: {
	      title: "用户管理",
	      icon: "",
	      menuType: "User",
	    },
	  },
      ...Menu,
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

const BaseTitle = "";

router.beforeEach(async (to, from, next) => {
  document.title = (to.meta.title ? to.meta.title : BaseTitle) as string;
  const BaseWhiteList = ["Login"];
  switch (true) {
    // case !getToken() && !BaseWhiteList.includes(to.name as string):
    //   next({ name: "Login" });
    //   break;
    // case getToken() && BaseWhiteList.includes(to.name as string):
    //   next({ name: "Home" });
    //   break;
    default:
      next();
      break;
  }
});

export default router;
